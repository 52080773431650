import React from "react"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Header from "../components/Header"
import Footer from "../components/Footer/Footer"
import Button from "../components/Button"
import { loadStripe } from "@stripe/stripe-js"
import CheckoutForm from "../components/Stripe/checkoutForm"
import CardCheckout from "../components/Stripe/cardCheckout"
import { Elements } from "@stripe/react-stripe-js"
import Seo from "../components/SEO"

class Payment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      amount: "0",
    }
  }

  updateAmount(event) {
    if (event.target.value.charAt(0) == "0" || event.target.value == "") {
      document.getElementById("cardOption").style.display = "none"
      document.getElementById("bankOption").style.display = "none"
      document.getElementById("errorMessage").style.display = "block"
    } else {
      if (event.target.value.charAt(0) == "€") {
        document.getElementById("errorMessage").style.display = "none"
        this.showHideStripeOptions()
        this.setState({
          amount: event.target.value,
        })
      } else {
        document.getElementById("errorMessage").style.display = "none"
        this.showHideStripeOptions()
        this.setState({
          amount: (event.target.value = "€" + event.target.value),
        })
      }
    }
  }

  stripePromise = loadStripe("pk_live_wSdIoQoOojXJM74H1GsdctCq")

  showHideStripeOptions = () => {
    var checkbox = document.getElementById("paymentOption")

    if (document.getElementById("errorMessage").style.display != "block") {
      if (checkbox.checked) {
        document.getElementById("cardOption").style.display = "none"
        document.getElementById("bankOption").style.display = "block"
      } else {
        document.getElementById("cardOption").style.display = "block"
        document.getElementById("bankOption").style.display = "none"
      }
    }
  }

  render() {
    return (
      <div>
        <Seo
          title={this.props.data.allContentfulSeo.nodes[6].title}
          description={this.props.data.allContentfulSeo.nodes[6].description}
          keywords={this.props.data.allContentfulSeo.nodes[6].keywords}
        />
        <Header />
        <section className="pay-padding d-flex">
          <div className="container  d-flex flex-column">
            <div className="row align-items-center justify-content-between content-area">
              <div className=" col-sm-12 col-md-5 col-lg-5 text-col">
                <h1 className="headings-of-all">Pay</h1>
                <h1>{this.props.data.allContentfulPayPage.nodes[0].title}</h1>
                <h3>
                  {documentToReactComponents(
                    JSON.parse(
                      this.props.data.allContentfulPayPage.nodes[0].description
                        .raw
                    )
                  )}
                </h3>
              </div>
              <div className=" col-sm-12 col-md-6 col-lg-6 contct-form-area">
                <div>
                  <div className="form-group">
                    <div className="payInput">
                      <label className="sr-only" style={{ marginTop: "block" }}>
                        Amount
                      </label>
                      <input
                        id="paymentAmount"
                        placeholder="€ Enter Amount"
                        name="payment-amount"
                        value={this.state.inputValue}
                        pattern="^-?[0-9]\d*\.?\d*$"
                        onChange={evt => this.updateAmount(evt)}
                        onKeyPress={event => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      />
                    </div>
                  </div>
                  <div
                    className="test-drive-plan-div"
                    style={{
                      backgroundColor: "white",
                      marginTop: "0%",
                      paddingTop: "0%",
                      marginBottom: "0%",
                      paddingBottom: "0%",
                    }}
                  >
                    <label htmlFor="paymentOption">
                      Please check this box if you would like to pay by SEPA
                      bank transfer &nbsp;&nbsp;
                      <span>
                        <input
                          type="checkbox"
                          id="paymentOption"
                          onClick={this.showHideStripeOptions}
                        />
                      </span>
                    </label>
                  </div>
                  <center>
                    <div
                      id="errorMessage"
                      style={{
                        display: "block",
                        marginBottom: "0%",
                        paddingBottom: "0%",
                      }}
                    >
                      <p style={{ color: "#243671" }}>
                        Please enter a valid amount to pay
                      </p>
                    </div>
                    <div
                      id="cardOption"
                      style={{
                        display: "none",
                        marginBottom: "0%",
                        paddingBottom: "0%",
                      }}
                    >
                      <Elements stripe={this.stripePromise}>
                        <CardCheckout
                          id="cardCheckout"
                          planamount={this.state.amount}
                          planname={"Once off payment"}
                        />
                      </Elements>
                    </div>
                    <div
                      id="bankOption"
                      style={{
                        display: "none",
                        marginBottom: "0%",
                        paddingBottom: "0%",
                      }}
                    >
                      <Elements stripe={this.stripePromise}>
                        <CheckoutForm
                          planamount={this.state.amount}
                          planname={"Once off payment"}
                        />
                      </Elements>
                    </div>
                  </center>
                </div>
              </div>
            </div>
            <div
              className="pay-stripe"
              style={{ marginBottom: "0%", paddingBottom: "0%" }}
            >
              <img
                style={{ marginBottom: "0%", paddingBottom: "0%" }}
                src={
                  this.props.data.allContentfulPayPage.nodes[0].image.file.url
                }
                alt="stripe"
              />
            </div>
          </div>
        </section>
        <section
          className="contact-section container contact-mat"
          style={{ marginTop: "0%", paddingTop: "0%" }}
        >
          <div className="payGif" style={{ marginTop: "0%", paddingTop: "0%" }}>
            <img
              id="payGif"
              src={this.props.data.allContentfulPayPage.nodes[0].gif.file.url}
              alt="Let's Talk GIF"
            />
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Payment

export const query = graphql`
  query {
    allContentfulPayPage {
      nodes {
        description {
          raw
        }
        title
        image {
          file {
            url
          }
        }
        gif {
          file {
            url
          }
        }
      }
    }
    allContentfulSeo(sort: { fields: createdAt }) {
      nodes {
        title
        description
        keywords
      }
    }
  }
`
